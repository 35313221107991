<template>
  <v-card class="mx-auto  justify-center" max-width="700" id = "padl">
    <v-card-title style="justify-content:center">
      <span class="headline">Authorization</span>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="login"
        label="Login"
        single-line
        counter
        required
        style="width:500px"
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        single-line
        counter
        required
        type="password"
        style="width:500px"
      ></v-text-field>
      <v-layout align-center justify-center>
        <div class="text-xs-center">
          <v-btn
            color="primary"
            class="mr-2"
            style="margin-top: 15px;"
            @click="submit"
          >
            LogIn
          </v-btn>
        </div>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require("axios").default;

export default {
  data: () => ({
    login: "",
    password: "",
  }),
  methods: {
    async submit() {
      let _this = this;
      let authBase64 = btoa(this.login + ":" + this.password);
      // 
      let configs = null;
      let getConfig = axios({
        url: "../../config.json",

      }).then((respose) => {
        configs = respose.data;
        return configs;
      });
      const config = await getConfig;
      let url = "";
      url;
      for (let i = 0; i < config.length; i++) {
        if (config[i].name === "api") {
          url = config[i].ip + "/Auth";
        }
      }
      // 
      await axios({
        url,
        // url: "http://localhost:5081/api/Webits/Auth",
        headers: { Authorization: "Basic " + authBase64 },
      })
        .then(function(response) {
          if (response.data) {
            localStorage.LastLogin = Date.now();
            _this.$router.push("adminpanel");
          }
        })
        .catch((error) => {
          alert(error);
          //   console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style>
#padl {
  margin-top: 200px;
}
</style>